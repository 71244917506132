@import "custom";

@font-face { font-family: HelveticaNeueBold; src: url('./assets/fonts/HelveticaNeueBold.ttf'); }
@font-face { font-family: HelveticaNeueLTProRoman; src: url('./assets/fonts/HelveticaNeueLTPro-Roman.otf'); } 

.header {
  text-align: center;
}

span {
  &.dangerText {
    color: #dc3545;
  }

  &.infoText {
    color: #17a2b8;
    font-weight: bold;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: HelveticaNeueBold, Arial, Helvetica, sans-serif;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.4rem;
}

p, a, label, input, button {
  font-family: HelveticaNeueLTProRoman, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}


.navigation {
  padding: 20px 20px 10px 20px;
}

.navigation .btn {
  margin-top: 5px;
  padding: 10px;
}

.first {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #001C42;
}


.first p, h1, h2 {
  color: white;
  text-align: center;
}

.first .btn {
  padding: 10px 20px 10px 20px;
}

.covertext {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}




.second img {
  width: 20%;
}

@media (max-width: 768px) {
  .first img {
    padding-top: 400px;
  }
  .covertext {
    margin-top: 150px ;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.second p, a {
  color: black;
  text-align: left;
}

.second > div {
  padding: 100px;
}

.third {
  background-color: #eeeff1;
  padding-top: 50px;
  padding-bottom: 50px;
}

.third h2 {
  color: black;
  text-align: left;
}

.third p {
  color: black;
  text-align: left;
}

.forth {
  padding-top: 50px;
  padding-bottom: 50px;
}

.forth h1 {
  color: black;
}

.fifth {
  padding: 100px 50px 0 50px;
  background-color: #eeeff1;
}

h1 {
  font-family: 'Montserrat', sans-serif;
}

h2, p {
  color: black;
}

h6 {
  font-family: 'Roboto', sans-serif;
  text-align: center;
}



.btn {
  border-radius: 0;
  background-color: #0b55ea;
  border: #0b55ea;
  font-family: 'Roboto', sans-serif;
}





// // FIRSTTEXT SPECIFIC STYLING


div.container {
  padding-left: 0;
  padding-right: 0;
  background-color: #001C42;
  text-align: center;
}

// div.row {
//   margin-right: 0px;
//   margin-left: 0px
// }

// div.col-lg-1,div.col-lg-10,div.col-lg-11,div.col-lg-12,div.col-lg-2,div.col-lg-3,div.col-lg-4,div.col-lg-5,div.col-lg-6,div.col-lg-7,div.col-lg-8,div.col-lg-9,
// div.col-md-1,div.col-md-10,div.col-md-11,div.col-md-12,div.col-md-2,div.col-md-3,div.col-md-4,div.col-md-5,div.col-md-6,div.col-md-7,div.col-md-8,div.col-md-9,
// div.col-sm-1,div.col-sm-10,div.col-sm-11,div.col-sm-12,div.col-sm-2,div.col-sm-3,div.col-sm-4,div.col-sm-5,div.col-sm-6,div.col-sm-7,div.col-sm-8,div.col-sm-9,
// div.col-xs-1,div.col-xs-10,div.col-xs-11,div.col-xs-12,div.col-xs-2,div.col-xs-3,div.col-xs-4,div.col-xs-5,div.col-xs-6,div.col-xs-7,div.col-xs-8,div.col-xs-9 {
//   padding-left: 0;
//   padding-right: 0;
// }




